import React from 'react';
import PropTypes from 'prop-types';
import {
  Snackbar as MuiSnackbar,
  SnackbarContent as MuiSnackbarContent,
  IconButton,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

export default function Snackbar(props) {
  const {
    open,
    onClose,
    message,
    variant,
    autoHideDuration,
  } = props;
  const classes = useStyles();

  const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
  };

  const Icon = variantIcon[variant];

  const popUpTypeColor = () => {
    switch (variant) {
      case 'success':
        return classes.success;
      case 'error':
        return classes.error;
      default:
        return '';
    }
  };

  return (
    <MuiSnackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={autoHideDuration}
    >
      <MuiSnackbarContent
        className={`${classes.wrapper} ${popUpTypeColor()}`}
        message={(
          <span
            id="message-id"
            className={classes.message}
          >
            <Icon className={classes.icon} />
            {message}
          </span>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            classes={{ root: classes.close }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.string,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
};

Snackbar.defaultProps = {
  open: false,
  message: '',
  variant: 'error',
  autoHideDuration: 6000,
  onClose: () => {},
};
