export const primary = {
  main: '#00233b',
  dark: '#e7eaed',
  light: '#969ca4',
  contrastText: '#ffffff',
};
export const secondary = {
  main: '#596975',
  dark: '#00233b',
  light: '#969ca4',
  contrastText: '#ffffff',
};
export const tertiary = {
  grey: '#dbddde',
  dimGrey: '#99a7b0',
  lightGrey: '#f8f9f9',
  mediumGrey: '#f4f5f7',
  darkGrey: '#e7eaed',

  cyprus: '#06363c',

  green: '#5bc699',
  lightGreen: '#ecf7ee',

  red: '#ee0000',
  alertRed: '#fc7a71',
  alertYellow: '#fdc74c',
};
export const font = {
  header: '#00233b',
  body: '#596975',
  subBody: '#969ca4',
};
