import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

export default function FormikRadioGroup(props) {
  const {
    field,
    form: {
      setFieldValue,
      values,
      touched,
      errors,
    },
    name,
    label,
    required,
    disabled,
    readOnly,
    options,
    ...rest
  } = props;

  const fieldName = name || field.name;

  const handleChange = (e) => {
    if (!readOnly) {
      setFieldValue(fieldName, e.target.value);
    }
  };

  const renderOptions = () => {
    if (options && options.length > 0) {
      return (
        options.map(option => (
          <FormControlLabel
            key={option.name}
            value={option.value}
            label={option.label}
            disabled={disabled}
            checked={values[fieldName] === option.value}
            control={<Radio color="primary" />}
          />
        ))
      );
    }
    return null;
  };

  const hasError = touched[fieldName] && !!errors[fieldName];
  return (
    <FormControl>
      <FormLabel
        required={required}
        disabled={disabled}
        error={hasError}
      >
        { label }
      </FormLabel>
      { hasError && (
        <FormHelperText error>
          { errors[fieldName] }
        </FormHelperText>
      )}
      <RadioGroup
        {...field}
        {...rest}
        name={fieldName}
        onChange={handleChange}
      >
        { renderOptions() }
      </RadioGroup>
    </FormControl>
  );
}

FormikRadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  field: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object),
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

FormikRadioGroup.defaultProps = {
  name: '',
  label: '',
  required: false,
  disabled: false,
  readOnly: false,
  field: {},
  form: {
    touched: {},
    errors: {},
  },
  options: [],
};
