import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../containers/App/theme';

export default makeStyles({
  container: {
    padding: '3rem 2rem 2rem 2rem',
    '&.white': {
      backgroundColor: 'white',
    },
  },
  terms: {
    margin: '0.5rem 0 0 0.5rem',
    wordBreak: 'break-word',
    maxHeight: '20vh',
    overflow: 'auto',
    ...fonts.body1,
    '& p': {
      margin: 0,
    },
    '& p + p': {
      marginTop: '0.5rem',
    },
  },
  description: {
    margin: '1rem 0',
    ...fonts.body1,
    fontFamily: fonts.bodyFont,
    '& p': {
      margin: 0,
    },
    '& p + p': {
      marginTop: '0.5rem',
    },
  },
  form: {
    '& > div': {
      width: '100%',
    },
    '& > div:first-child': {
      marginTop: '2rem',
    },
    '& > div:not(:first-child)': {
      marginTop: '2.5rem',
    },
  },
  mobileWrapper: {
    display: 'flex',
    '& > div:last-child': {
      flex: 1,
    },
  },
});
