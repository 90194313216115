export default function oddOrEven(number) {
  // Make sure is a number
  const formattedNumber = Number.isInteger(number) ? number : Number(number);
  if (!Number.isNaN(formattedNumber)) {
    if (formattedNumber % 2 === 0) {
      return 'even';
    }
    return 'odd';
  }
  return '';
}
