import { font } from './color';

export const titleFont = 'Montserrat, sans-serif';
export const bodyFont = 'Open Sans, sans-serif';
export const semiBold = 600;
export const bold = 700;

export const h1 = {
  fontFamily: titleFont,
  fontWeight: semiBold,
  fontSize: '1.7rem',
  color: font.header,
  marginBottom: '4px',
};
export const h2 = {
  fontFamily: titleFont,
  fontWeight: semiBold,
  fontSize: '1.625rem',
  color: font.header,
};
export const h3 = {
  fontFamily: titleFont,
  fontWeight: semiBold,
  fontSize: '1.5rem',
  color: font.header,
};
export const h4 = {
  fontFamily: titleFont,
  fontWeight: semiBold,
  fontSize: '1.375rem',
  color: font.header,
};
export const h5 = {
  fontFamily: titleFont,
  fontWeight: semiBold,
  fontSize: '1.25rem',
  color: font.header,
};
export const h6 = {
  fontFamily: titleFont,
  fontWeight: semiBold,
  fontSize: '1.1rem',
  color: font.header,
};
export const body1 = {
  fontSize: '1rem',
  color: font.body,
};
export const body2 = {
  fontSize: '0.875rem',
  color: font.body,
};
export const subtitle1 = {
  fontSize: '1rem',
  fontWeight: semiBold,
  color: font.header,
};
export const subtitle2 = {
  fontSize: '0.875rem',
  fontWeight: semiBold,
  color: font.header,
};
export const caption = {
  fontSize: '0.72rem',
  color: font.body,
};
export const overline = {
  ...caption,
  fontWeight: bold,
};

export const buttonFont = {
  fontSize: '1rem',
  fontWeight: semiBold,
};
export const smallButtonFont = {
  fontSize: '0.875rem',
  fontWeight: 400,
};

export const modalTitleFont = {
  fontFamily: titleFont,
  fontWeight: semiBold,
  ...h3,
};
