import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@material-ui/core';
import { useStyles, CustomMenuItem } from './styles';

export default function FormikMobilePrefixSelect(props) {
  const {
    field,
    name,
    associatedName,
    label,
    disabled,
    readOnly,
    options,
    form: {
      setFieldValue,
    },
    ...rest
  } = props;

  const classes = useStyles();

  const fieldName = name || field.name;

  const handleChange = (e) => {
    if (!readOnly) {
      setFieldValue(fieldName, e.target.value);
    }
  };

  return (
    <Select
      {...field}
      {...rest}
      name={fieldName}
      placeholder="Select"
      disabled={disabled}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected) {
          const selectedValue = options.find(obj => (obj.value === selected));
          if (selectedValue) {
            return (
              <>
                <span className={`flag-icon flag-icon-${selected.toLowerCase()} ${classes.flag}`} />
                {`+${selectedValue.calling_code}`}
              </>
            );
          }
        }
        return <span className={classes.placeholder}>Select</span>;
      }}
    >
      {
        options.map(option => (
          <CustomMenuItem
            key={option.value !== 'n/a' ? `${option.value}_${option.calling_code}` : option.value}
            value={option.value}
          >
            {
              option.value !== 'n/a' && (
                <span className={`flag-icon flag-icon-${option.value.toLowerCase()}`} />
              )
            }
            { `${option.label} (+${option.calling_code})` }
          </CustomMenuItem>
        ))
      }
    </Select>
  );
}

FormikMobilePrefixSelect.propTypes = {
  name: PropTypes.string,
  associatedName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  field: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object),
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

FormikMobilePrefixSelect.defaultProps = {
  name: '',
  associatedName: '',
  label: '',
  disabled: false,
  readOnly: false,
  field: {},
  form: {},
  options: [],
};
