import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
} from '@material-ui/core';
import { useStyles, CustomMenuItem } from './styles';

export default function FormikSelect(props) {
  const {
    field,
    name,
    label,
    readOnly,
    required,
    disabled,
    options,
    onChange,
    form: {
      touched,
      errors,
    },
    ...rest
  } = props;

  const classes = useStyles();

  const fieldName = name || field.name;
  const hasError = touched[field.name] && !!errors[field.name];

  const handleChange = (e) => {
    if (!readOnly) {
      onChange(e);
    }
  };

  return (
    <FormControl>
      <FormLabel
        required={required}
        disabled={disabled}
        error={hasError}
      >
        { label }
      </FormLabel>
      <Select
        {...field}
        {...rest}
        fullWidth
        displayEmpty
        name={fieldName}
        placeholder="Select"
        disabled={disabled}
        error={hasError}
        onChange={handleChange}
        renderValue={(selected) => {
          if (!selected) {
            return <span className={classes.placeholder}>Select</span>;
          }
          const optionObject = options.find(option => option.value === selected);
          return (optionObject && optionObject.label) || '';
        }}
      >
        {
          options.map(option => (
            <CustomMenuItem
              key={option.value}
              value={option.value}
            >
              { option.label }
            </CustomMenuItem>
          ))
        }
      </Select>
      { hasError && (
        <FormHelperText error>
          { errors[fieldName] }
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormikSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object),
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onChange: PropTypes.func,
};

FormikSelect.defaultProps = {
  name: '',
  label: '',
  readOnly: false,
  required: false,
  disabled: false,
  field: {},
  form: {},
  options: [],
  onChange: () => {},
};
