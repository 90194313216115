import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Icon } from '@material-ui/core';
import { CalendarIcon } from '../../assets/icons';
import useStyles from './styles';

export default function FormikDatePicker(props) {
  const {
    field,
    form: {
      setFieldValue,
      touched,
      errors,
    },
    required,
    disabled,
    readOnly,
    name,
    label,
  } = props;

  const classes = useStyles();

  const fieldName = name || field.name;
  const hasError = touched[fieldName] && !!errors[fieldName];

  const formatDateValue = (value) => {
    if (value) {
      return moment(value).format('YYYY-MM-DD');
    }
    return null;
  };

  return (
    <>
      <KeyboardDatePicker
        clearable
        fullWidth
        readOnly={readOnly}
        required={required}
        disabled={disabled}
        openTo="year"
        views={['year', 'month', 'date']}
        format="YYYY/MM/DD"
        placeholder="YYYY/MM/DD"
        name={fieldName}
        label={label}
        error={hasError}
        helperText={touched[fieldName] && errors[fieldName]}
        value={field.value}
        onChange={value => setFieldValue(fieldName, formatDateValue(value), false)}
        InputLabelProps={{
          shrink: false,
          variant: 'standard',
          disableAnimation: true,
        }}
        InputProps={{
          readOnly,
        }}
        keyboardIcon={(
          <Icon>
            <img alt="date" className={classes.icon} src={CalendarIcon} />
          </Icon>
        )}
      />
    </>
  );
}

FormikDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  field: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object),
};

FormikDatePicker.defaultProps = {
  name: '',
  label: '',
  required: false,
  disabled: false,
  readOnly: false,
  field: {},
  form: {
    touched: {},
    errors: {},
  },
};
