import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Icon } from '@material-ui/core';
import { TimeIcon } from '../../assets/icons';
import useStyles from './styles';

export default function FormikTimePicker(props) {
  const {
    field,
    form: {
      setFieldValue,
      touched,
      errors,
    },
    name,
    label,
    required,
    disabled,
    readOnly,
  } = props;

  const classes = useStyles();

  const [time, setTime] = useState(null);

  const fieldName = name || field.name;
  const hasError = touched[fieldName] && !!errors[fieldName];

  const handleChange = (value) => {
    setTime(value || null);
    setFieldValue(fieldName, value ? moment(value).format('HH:mm') : null, false);
  };

  return (
    <>
      <KeyboardTimePicker
        clearable
        fullWidth
        readOnly={readOnly}
        required={required}
        placeholder="08:00 AM"
        mask="__:__ _M"
        name={fieldName}
        label={label}
        disabled={disabled}
        error={hasError}
        helperText={touched[fieldName] && errors[fieldName]}
        value={time}
        onChange={handleChange}
        InputLabelProps={{
          shrink: false,
          variant: 'standard',
          disableAnimation: true,
        }}
        InputProps={{
          readOnly,
        }}
        keyboardIcon={(
          <Icon>
            <img alt="date" className={classes.icon} src={TimeIcon} />
          </Icon>
        )}
      />
    </>
  );
}

FormikTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  field: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object),
};

FormikTimePicker.defaultProps = {
  name: '',
  label: '',
  required: false,
  disabled: false,
  readOnly: false,
  field: {},
  form: {
    touched: {},
    errors: {},
  },
};
