import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Grid } from '@material-ui/core';

import './styles.css';

const LoadingPage = ({ isLoading, error }) => {
  if (isLoading) {
    return (
      <Grid container alignItems="center" justify="center" className="LoadingPage">
        <CircularProgress color="primary" size="3rem" />
      </Grid>
    );
  }
  if (error) {
    return (
      <div className="LoadingPageProblem">
        <h3>
          Sorry, there was a problem loading the page.
        </h3>
      </div>
    );
  }
  return null;
};

LoadingPage.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

LoadingPage.defaultProps = {
  isLoading: false,
  error: null,
};

export default LoadingPage;
