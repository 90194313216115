import { createMuiTheme } from '@material-ui/core';
import * as font from './font';
import * as color from './color';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: color.primary,
    secondary: color.secondary,
    tertiary: color.tertiary,
    font: color.font,
    text: {
      primary: color.font.header,
    },
    error: {
      main: color.tertiary.red,
      dark: defaultTheme.palette.error.dark,
      light: color.tertiary.alertRed,
    },
  },
  typography: {
    fontFamily: font.bodyFont,
    color: color.font.body,
  },
  overrides: {
    MuiTypography: {
      h1: font.h1,
      h2: font.h2,
      h3: font.h3,
      h4: font.h4,
      h5: font.h5,
      h6: font.h6,
      subtitle1: font.subtitle1,
      subtitle2: font.subtitle2,
      body1: font.body1,
      body2: font.body2,
      caption: font.caption,
    },

    MuiButtonGroup: {
      groupedContained: {
        ...font.buttonFont,
      },
    },

    MuiButton: {
      root: {
        ...font.buttonFont,
        minWidth: '70px',
        minHeight: '35px',
        padding: '0 1rem',
        '&:disabled': {
          color: color.tertiary.grey,
          '& img': {
            filter: 'invert(50%)',
            opacity: '0.5',
          },
        },
      },
      label: {
        textTransform: 'none',
      },
      contained: {
        backgroundColor: color.secondary.main,
        color: defaultTheme.palette.common.white,
        '&:hover': {
          backgroundColor: color.secondary.light,
        },
        '&:disabled': {
          color: color.tertiary.grey,
          backgroundColor: color.tertiary.mediumGrey,
          '& img': {
            filter: 'invert(50%)',
            opacity: '0.5',
          },
        },
        '&:not(:disabled) img': {
          filter: 'invert(1)',
        },
      },
      text: {
        padding: '0.36rem 1.43rem',
        '&:hover': {
          backgroundColor: 'unset',
          color: color.font.body,
        },
        '&:active': {
          color: color.font.subBody,
        },
        '&:disabled': {
          color: color.tertiary.grey,
        },
      },
      outlined: {
        color: color.font.body,
        borderColor: color.tertiary.grey,
        padding: '0 1rem',
        '&:hover': {
          borderColor: color.tertiary.mediumGrey,
          backgroundColor: color.tertiary.mediumGrey,
        },
        '&:disabled': {
          border: `1px solid ${color.tertiary.lightGrey}`,
          backgroundColor: color.tertiary.mediumGrey,
          '& img': {
            opacity: '0.2',
          },
        },
        '&:not(:disabled) img': {
          filter: 'invert(42%) sepia(23%) saturate(316%) hue-rotate(163deg) brightness(89%) contrast(92%)',
        },
      },
      sizeSmall: {
        ...font.smallButtonFont,
        minWidth: 'unset',
        minHeight: '30px',
        padding: '0 1rem',
        '& .MuiButton-label .MuiButton-startIcon img': {
          width: '20px',
          height: '18px',
        },
      },
      startIcon: {
        marginRight: '4px',
      },
    },

    MuiCheckbox: {
      root: {
        '& > span:first-child svg': {
          width: '18px',
          height: '18px',
        },
      },
      colorPrimary: {
        color: color.primary.light,
        '&$checked': {
          color: color.primary.main,
        },
        '&$disabled': {
          color: color.tertiary.mediumGrey,
        },
      },
    },

    MuiRadio: {
      root: {
        '& .MuiSvgIcon-root': {
          height: '18px',
          width: '18px',
        },
      },
      colorPrimary: {
        color: color.primary.light,
        '&$checked': {
          color: color.primary.main,
        },
        '&$disabled': {
          color: color.tertiary.mediumGrey,
        },
      },
    },

    MuiInputLabel: {
      formControl: {
        position: 'unset',
        transform: 'unset',
        marginBottom: '0.5rem',
        ...font.subtitle1,
      },
      root: {
        ...font.subtitle1,
        marginBottom: '0.5rem',
        '& .MuiInputLabel-asterisk': {
          color: color.tertiary.red,
        },
      },
    },

    MuiFormLabel: {
      root: {
        ...font.subtitle1,
        marginBottom: '0.5rem',
        '& .MuiFormLabel-asterisk': {
          color: color.tertiary.red,
        },
      },
    },

    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: color.tertiary.grey,
        },
      },
    },

    MuiFormHelperText: {
      contained: {
        marginTop: '3px',
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MuiFormControl: {
      root: {
        '& label + .MuiInput-formControl': {
          marginTop: 0,
        },
      },
    },

    MuiIcon: {
      root: {
        '& img': {
          width: '100%',
          height: '100%',
        },
      },
    },

    MuiInputBase: {
      root: {
        borderRadius: '4px',
        '&$disabled': {
          color: color.font.subBody,
          backgroundColor: color.tertiary.mediumGrey,
        },
        '&$disabled > svg': {
          color: color.tertiary.grey,
        },
        'label[data-shrink=false] + &$formControl input::-webkit-input-placeholder': {
          color: color.font.subBody,
          opacity: '1 !important',
        },
      },
      input: {
        '&:disabled': {
          opacity: '0.5',
        },
        '&:-webkit-autofill': {
          boxShadow: '0 0 0 30px white inset',
        },
        '&:disabled:-webkit-autofill': {
          boxShadow: `0 0 0 30px ${color.tertiary.mediumGrey} inset`,
        },
        '&::placeholder': {
          color: color.font.subBody,
          opacity: '1 !important',
        },
      },
    },

    MuiPickersBasePicker: {
      pickerView: {
        width: '355px',
        maxWidth: '355px',
        '& .MuiPickersMonthSelection-container': {
          width: 'unset',
        },
        [defaultTheme.breakpoints.down('xs')]: {
          width: 'unset',
          minWidth: 'unset',
        },
      },
    },

    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '180px',
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        padding: '0 30px',
        '& > button:first-child': {
          paddingLeft: 0,
        },
        '& > button:last-child': {
          paddingRight: 0,
        },
        '& p': {
          fontSize: '12px',
        },
      },
      daysHeader: {
        '& $dayLabel': {
          color: color.font.subBody,
          fontWeight: font.bold,
        },
      },
      transitionContainer: {
        '& > p': {
          color: color.font.header,
          fontWeight: font.semiBold,
        },
      },
    },

    MuiPaper: {
      root: {
        border: `1px solid ${color.tertiary.grey}`,
      },
    },

    MuiSelect: {
      icon: {
        color: color.font.body,
      },
      select: {
        '&:focus': {
          backgroundColor: 'unset',
        },
        '&$disabled': {
          border: `1px solid ${color.tertiary.mediumGrey}`,
          '& p': {
            color: color.font.subBody,
          },
        },
      },
      selectMenu: {
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});

export default theme;
