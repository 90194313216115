import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import LoadingPage from './components/LoadingPage';

const NotFound = lazy(() => import('./widgets/home/screens/NotFound'));
const Success = lazy(() => import('./widgets/home/screens/Success'));
const Form = lazy(() => import('./widgets/home/screens/Form'));

const Routes = () => (
  <Suspense fallback={<LoadingPage isLoading />}>
    <Switch>
      <Route path="/" exact component={NotFound} />
      <Route path="/not_found" exact component={NotFound} />
      <Route path="/preview/:id" exact component={Form} />
      <Route path="/:id/success" exact component={Success} />
      <Route path="/:id" component={Form} />
    </Switch>
  </Suspense>
);

export default Routes;
