import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  error: {
    background: theme.palette.tertiary.alertRed,
  },
  success: {
    background: theme.palette.tertiary.green,
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  close: {
    fontSize: 20,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  wrapper: {
    border: 'unset',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));
