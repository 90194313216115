import React from 'react';
import PropTypes from 'prop-types';
import { config } from '../../configs';

const FormHeader = ({ data }) => {
  const renderImage = () => {
    if (data.image_url) {
      const { bucket, region } = config.s3;
      const src = `https://s3-${region}.amazonaws.com/${bucket}/public/${data.image_url}`;
      return (
        <img alt="header_image" src={src} />
      );
    }
    return null;
  };

  return (
    <div className="FormHeader">
      { renderImage() }
    </div>
  );
};

FormHeader.propTypes = {
  data: PropTypes.shape({
    head_bg_color: PropTypes.string,
    head_bg_img: PropTypes.string,
    image_url: PropTypes.string,
  }),
};

FormHeader.defaultProps = {
  data: null,
};

export default FormHeader;
