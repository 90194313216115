export const states = [
  { label: 'Johor', value: 'Johor' },
  { label: 'Kedah', value: 'Kedah' },
  { label: 'Kelantan', value: 'Kelantan' },
  { label: 'Melaka', value: 'Malacca' },
  { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { label: 'Pahang', value: 'Pahang' },
  { label: 'Perlis', value: 'Perlis' },
  { label: 'Perak', value: 'Perak' },
  { label: 'Pulau Pinang', value: 'Penang' },
  { label: 'Sabah', value: 'Sabah' },
  { label: 'Sarawak', value: 'Sarawak' },
  { label: 'Selangor', value: 'Selangor' },
  { label: 'Terengganu', value: 'Terengganu' },
  { label: 'Wilayah Persekutuan Kuala Lumpur', value: 'Kuala Lumpur' },
  { label: 'Wilayah Persekutuan Labuan', value: 'Labuan' },
  { label: 'Wilayah Persekutuan Putrajaya', value: 'Putrajaya' },
];

export default states;
