import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@material-ui/core';

export default function FormikCheckboxGroup(props) {
  const {
    name,
    label,
    options,
    required,
    readOnly,
    disabled,
    remove,
    insert,
    form: {
      touched,
      errors,
      values,
    },
  } = props;

  const handleChange = (e, optionLabel, index) => {
    if (!readOnly) {
      const { value, checked } = e.target;
      if (checked) {
        insert(index, value);
      } else {
        const getIndex = values[name].findIndex(v => v === value);
        if (getIndex > -1) {
          remove(getIndex);
        }
      }
    }
  };

  const renderOptions = () => {
    if (options && options.length > 0) {
      return (
        options.map((option, index) => (
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                name={option.name}
                value={option.value}
                onChange={e => handleChange(e, option.label, index)}
              />
            )}
            key={option.name}
            label={option.label}
            disabled={disabled}
            checked={values && values[name] && values[name].includes(option.value)}
          />
        ))
      );
    }
    return null;
  };

  const hasError = touched[name] && errors[name] && typeof errors[name] === 'string';
  return (
    <FormControl>
      <FormLabel
        required={required}
        disabled={disabled}
        error={hasError}
      >
        { label }
      </FormLabel>
      { hasError && (
        <FormHelperText error>
          { errors[name] }
        </FormHelperText>
      )}
      { renderOptions() }
    </FormControl>
  );
}

FormikCheckboxGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  form: PropTypes.instanceOf(Object),
  remove: PropTypes.func,
  insert: PropTypes.func,
};

FormikCheckboxGroup.defaultProps = {
  name: '',
  label: '',
  disabled: false,
  required: false,
  readOnly: false,
  options: [],
  form: {
    touched: {},
    errors: {},
    values: [],
  },
  remove: () => {},
  insert: () => {},
};
