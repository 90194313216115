import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { MHubLogo } from '../../assets/logo';

import './styles.css';

const FormFooter = () => (
  <Grid container alignItems="center" justify="center" className="FormFooter">
    <Typography variant="caption">Powered By</Typography>
    <img alt="mhub_logo" src={MHubLogo} />
  </Grid>
);

export default FormFooter;
