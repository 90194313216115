/* eslint react/jsx-filename-extension: 0 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import configs from './configs';

import App from './containers/App';
// import * as serviceWorker from './serviceWorker';

import './index.css';

global.fetch = require('node-fetch');

const bugsnagClient = bugsnag(configs.bugsnag);
bugsnagClient.use(bugsnagReact, React);
window.bugsnagClient = bugsnagClient;

const ErrorBoundary = bugsnagClient.getPlugin('react');

Amplify.configure(configs.aws);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
