export default {
  app: {
    version: process.env.REACT_APP_VERSION,
  },
  s3: {
    bucket: process.env.REACT_APP_S3_PUBLIC_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
  api: {
    leadApiUrl: process.env.REACT_APP_MHUB_LEAD_API_URL,
  },
  bugsnag: {
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  },
};
