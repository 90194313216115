import ReactHtmlParser, {
  convertNodeToElement,
} from 'react-html-parser';

const transform = (node, index) => {
  // Append protocol if don't have
  if (node.type === 'tag' && node.name === 'a') {
    const newNode = node;
    const regex = new RegExp(/^(https?:\/\/)/gi);
    const hasProtocol = regex.test(node.attribs.href);
    if (!hasProtocol) {
      newNode.attribs.href = `//${node.attribs.href}`;
    }
    return convertNodeToElement(newNode, index, transform);
  }
  if (node.type === 'tag' && (node.name === 'p' || node.name === 'div')) {
    const newNode = node;
    delete newNode.attribs.style;
    return convertNodeToElement(newNode, index, transform);
  }
  return undefined;
};

export default function renderParsedHTML(src) {
  if (src && typeof src === 'string') {
    return ReactHtmlParser(src, { transform });
  }
  return null;
}
