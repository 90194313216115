import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  flag: {
    margin: '0 0.5rem',
    width: '2rem',
  },
  placeholder: {
    color: theme.palette.font.subBody,
  },
}));

export const CustomMenuItem = withStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.tertiary.mediumGrey,
    },
    '& > span': {
      marginRight: '1rem',
    },
  },
  selected: {},
}))(MenuItem);
