import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  Field,
  FieldArray,
  Form,
  Formik,
} from 'formik';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import Snackbar from '../Snackbar';
import FormikRadioGroup from '../FormikRadioGroup';
import FormikCheckboxGroup from '../FormikCheckboxGroup';
import FormikDatePicker from '../FormikDatePicker';
import FormikTimePicker from '../FormikTimePicker';
import FormikMobilePrefixSelect from '../FormikMobilePrefixSelect';
import FormikSelect from '../FormikSelect';

import { emailValidation, myNRICValidation } from '../../utils/validations';
import renderParsedHTML from '../../utils/parse';
import isIframe from '../../utils/iframe';
import { countries, states } from '../../constants';
import { config } from '../../configs';
import getInfoFromIC from '../../utils/generate';

import useStyles from './styles';

function SubmissionForm(props) {
  const {
    data,
    location,
    history,
    match,
  } = props;

  const classes = useStyles();

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [fields, setFields] = useState([]);
  const [isSwitchableIdentityField, setIsSwitchableIdentityField] = useState(false);
  const [isNationalityAndNRIC, setIsNationalityAndNRIC] = useState(false);

  useEffect(() => {
    if (data.fields) {
      if (['nric', 'passport', 'nationality_country_code']
        .every(name => data.fields.findIndex(field => field.name === name) > -1)) {
        setIsSwitchableIdentityField(true);
        // Always start with nric only first because default nationality is MY
        setFields(() => data.fields.filter(field => field.name !== 'passport'));
      } else if (['nric', 'nationality_country_code']
        .every(name => data.fields.findIndex(field => field.name === name) > -1)) {
        setIsNationalityAndNRIC(true);
        setFields(() => {
          const index = data.fields.findIndex(field => field.name === 'nric');
          const clonedFields = JSON.parse(JSON.stringify(data.fields));
          if (index > -1) {
            clonedFields[index].required = true;
          }
          return clonedFields;
        });
      } else {
        setFields(data.fields);
      }
    }
  }, [data.fields]);

  const isPreview = () => {
    const path = match.path.split('/');
    return path.length >= 3 && path[1] === 'preview';
  };

  const getInitialTelData = (field) => {
    if (['mobile', 'emergency_contact_mobile'].includes(field.name)) {
      return {
        [`${field.name}_country_code`]: 'MY',
        [field.name]: '',
      };
    }
    return {
      [field.name]: '',
    };
  };

  const getInitialSearchData = (field) => {
    if (field.name === 'nationality_country_code') {
      return {
        [field.name]: 'MY',
      };
    }
    return {
      [field.name]: '',
    };
  };

  const formatFieldType = (field, values) => {
    if (field.name === 'state') {
      if (!isEmpty(values) && values.country_code && values.country_code === 'MY') {
        return 'select';
      }
      return 'text';
    }
    return field.input_type;
  };

  const formatFieldOption = (field, values) => {
    if (['checkbox', 'select', 'radio'].includes(formatFieldType(field, values))) {
      if (field.name === 'state') {
        return states;
      }
      return field.options || [];
    }
    return null;
  };

  const getInitialData = () => {
    let initialData = {};
    if (fields && fields.length > 0) {
      fields.forEach((field) => {
        switch (formatFieldType(field)) {
          case 'checkbox':
            initialData = {
              ...initialData,
              [field.name]: [],
            };
            break;
          case 'radio':
          case 'email':
          case 'text':
          case 'textarea':
          case 'number':
            initialData = {
              ...initialData,
              [field.name]: '',
            };
            break;
          case 'select':
            initialData = {
              ...initialData,
              ...getInitialSearchData(field),
            };
            break;
          case 'tel':
            initialData = {
              ...initialData,
              ...getInitialTelData(field),
            };
            break;
          case 'date':
          case 'time':
            initialData = {
              ...initialData,
              [field.name]: null,
            };
            break;
          default:
            break;
        }
      });
    }
    return initialData;
  };

  const getFieldIndex = (name) => {
    if (fields && fields.length > 0) {
      return fields.findIndex(field => field.name === name);
    }
    return -1;
  };

  const handleTextChange = (e, formik) => {
    const {
      handleChange,
      setFieldValue,
    } = formik;
    const { name, value } = e.target;

    // only allow certain numbers on nric text field
    const reg = /^[0-9\b]+$/;
    if (['nric', 'emergency_contact_nric'].includes(name) && value !== '' && !reg.test(e.target.value)) {
      return;
    }

    if (name === 'nric') {
      const noDOBField = getFieldIndex('date_of_birth') <= -1;
      const noGenderField = getFieldIndex('gender') <= -1;

      if (value) {
        const nricInfo = getInfoFromIC(value);
        if (nricInfo.dateOfBirth) {
          setFieldValue('date_of_birth', nricInfo.dateOfBirth);
        } else if (noDOBField) {
          setFieldValue('date_of_birth', null);
        }
        if (nricInfo.gender) {
          setFieldValue('gender', nricInfo.gender);
        } else if (noGenderField) {
          setFieldValue('gender', '');
        }
      } else {
        // Remove DOB/Gender values if generate by NRIC
        // but user remove + don't have those fields in the form
        if (noDOBField) {
          setFieldValue('date_of_birth', null);
        }
        if (noGenderField) {
          setFieldValue('gender', '');
        }
      }
    }

    // CRM postcode is a number type but SR is a string
    // So we need to show CRM field type but convert into string for SR side
    if (name === 'postcode') {
      setFieldValue([name], value.toString());
    } else {
      handleChange(e);
    }
  };

  const handleChangeSelect = (e, formik) => {
    const {
      values,
      setFieldValue,
    } = formik;
    const { name, value } = e.target;
    switch (name) {
      case 'country_code':
        // Remove state value if country_code changed to 'MY'
        // because the state field will change from text field to a dropdown if country_code is 'MY'
        // and the dropdown might not contain the value that user previously typed in the text field
        if (values.country_code !== value
          && value === 'MY') {
          setFieldValue('state', '');
        }
        setFieldValue(name, value);
        break;
      case 'nationality_country_code':
        // Nationality field need to reset either passport/nric based on selections
        if (isSwitchableIdentityField) {
          if (value === 'MY') {
            if (values.passport) {
              setFieldValue('passport', '');
            }
            setFields(() => data.fields.filter(field => field.name !== 'passport'));
          } else {
            if (values.nric) {
              setFieldValue('nric', '');
            }
            setFields(() => data.fields.filter(field => field.name !== 'nric'));
          }
        } else if (isNationalityAndNRIC) {
          const nricIndex = fields.findIndex(field => field.name === 'nric');
          if (nricIndex > -1) {
            const { required } = fields[nricIndex];
            // if there's no passport,
            // nric requied state is depending on nationality value
            if (value === 'MY' && !required) {
              setFields((prev) => {
                const index = prev.findIndex(field => field.name === 'nric');
                const clonedFields = JSON.parse(JSON.stringify(prev));
                if (index > -1) {
                  clonedFields[index].required = true;
                }
                return clonedFields;
              });
            } else if (value !== 'MY' && required) {
              setFields((prev) => {
                const index = prev.findIndex(field => field.name === 'nric');
                const clonedFields = JSON.parse(JSON.stringify(prev));
                if (index > -1) {
                  clonedFields[index].required = false;
                }
                return clonedFields;
              });
            }
          }
        }
        setFieldValue(name, value);
        break;
      default:
        setFieldValue(name, value);
        break;
    }
  };

  const renderFieldType = (formik, field) => {
    const {
      values,
      handleChange,
      errors,
      isSubmitting,
      submitCount,
    } = formik;
    const clickedSubmit = submitCount > 0;
    const hasError = clickedSubmit && !!errors[field.name];
    const hasMobilePrefix = ['mobile', 'emergency_contact_mobile'].includes(field.name);

    switch (formatFieldType(field, values)) {
      case 'checkbox':
        return (
          <FieldArray
            name={field.name}
            render={helpers => FormikCheckboxGroup({
              ...helpers,
              readOnly: isPreview(),
              disabled: isSubmitting,
              required: field.required,
              label: field.label,
              options: formatFieldOption(field, values),
              origin: data.origin,
            })}
          />
        );
      case 'select':
        return (
          <Field
            name={field.name}
            label={field.label}
            readOnly={isPreview()}
            required={field.required}
            disabled={isSubmitting}
            options={formatFieldOption(field, values)}
            component={FormikSelect}
            onChange={(e) => { handleChangeSelect(e, formik); }}
          />
        );
      case 'date':
        return (
          <Field
            name={field.name}
            label={field.label}
            required={field.required}
            readOnly={isPreview()}
            disabled={isSubmitting}
            component={FormikDatePicker}
          />
        );
      case 'email':
      case 'number':
      case 'text':
        return (
          <TextField
            fullWidth
            required={field.required}
            disabled={isSubmitting}
            error={hasError}
            helperText={clickedSubmit && errors[field.name]}
            type={field.input_type}
            name={field.name}
            label={field.label}
            value={values[field.name] || ''}
            onChange={(e) => { handleTextChange(e, formik); }}
            InputLabelProps={{
              shrink: false,
              variant: 'standard',
            }}
            InputProps={{
              readOnly: isPreview(),
            }}
          />
        );
      case 'radio':
        return (
          <Field
            name={field.name}
            label={field.label}
            required={field.required}
            disabled={isSubmitting}
            readOnly={isPreview()}
            options={formatFieldOption(field, values) || []}
            component={FormikRadioGroup}
          />
        );
      // Only supports mobile prefix for mobile and emergency
      case 'tel':
        return (
          <FormControl>
            <FormLabel
              required={field.required}
              disabled={isSubmitting}
              error={hasError}
            >
              { field.label }
            </FormLabel>
            <div className={classes.mobileWrapper}>
              {
                hasMobilePrefix && (
                  <Field
                    name={`${field.name}_country_code`}
                    associatedName={field.name}
                    disabled={isSubmitting}
                    readOnly={isPreview()}
                    options={countries}
                    component={FormikMobilePrefixSelect}
                  />
                )
              }
              <TextField
                type="tel"
                name={field.name}
                value={values[field.name]}
                disabled={isSubmitting}
                error={hasError}
                onChange={handleChange}
                InputProps={{
                  readOnly: isPreview(),
                }}
              />
            </div>
            { clickedSubmit && errors[field.name] && (
              <FormHelperText error>
                { errors[field.name] }
              </FormHelperText>
            )}
          </FormControl>
        );
      case 'textarea':
        return (
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            required={field.required}
            disabled={isSubmitting}
            error={hasError}
            helperText={clickedSubmit && errors[field.name]}
            name={field.name}
            label={field.label}
            value={values[field.name]}
            onChange={handleChange}
            InputLabelProps={{
              shrink: false,
              variant: 'standard',
            }}
            InputProps={{
              readOnly: isPreview(),
            }}
          />
        );
      case 'time':
        return (
          <Field
            name={field.name}
            label={field.label}
            required={field.required}
            disabled={isSubmitting}
            readOnly={isPreview()}
            component={FormikTimePicker}
          />
        );
      default:
        break;
    }
    return null;
  };

  const handleSubmit = (values, actions) => {
    const isDisabled = isPreview() || !!(data.terms_and_conditions && !agreeTerms);

    const submit = async () => {
      const { setSubmitting } = actions;
      setSubmitting(true);
      const newValues = JSON.parse(JSON.stringify(values));

      // Follow lead: Group emergency contact fields under emergency_contacts
      const emergencyContactFields = [
        'emergency_contact_name',
        'emergency_contact_mobile',
        'emergency_contact_mobile_country_code',
        'emergency_contact_email',
        'emergency_contact_nric',
        'emergency_contact_relation',
      ];
      let combinedEmergencyContacts = {};
      Object.keys(newValues).forEach((key) => {
        if (emergencyContactFields.includes(key)) {
          const formattedName = key.replace('emergency_contact_', '');
          const fieldValue = newValues[key];
          combinedEmergencyContacts = {
            ...combinedEmergencyContacts,
            [formattedName]: fieldValue,
          };
          delete newValues[key];
        }
      });
      if (!isEmpty(combinedEmergencyContacts)) {
        newValues.emergency_contacts = [
          { ...combinedEmergencyContacts },
        ];
      }

      if (data.default_form_source) {
        newValues.source = data.default_form_source;
      }

      // Get UTM url params
      const params = qs.parse(location.search);
      const utmParams = [
        'utm_campaign',
        'utm_source',
        'utm_medium',
        'utm_term',
        'utm_content',
      ];
      utmParams.forEach((param) => {
        if (params[param]) {
          newValues[param] = params[param];
        }
      });

      const formatted = JSON.stringify({
        form_id: data.id,
        form_name: data.name,
        campaign_id: data.campaign_id,
        company_id: data.company_id,
        ...newValues,
      });

      fetch(`${config.api.leadApiUrl}/submissions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: formatted,
      }).then(async (response) => {
        if (response.ok) {
          if (data && data.external_success_url) {
            if (isIframe()) {
              // Change the most top parent url
              window.top.location.href = data.external_success_url;
            } else {
              window.location.href = data.external_success_url;
            }
          } else {
            history.push(`/${match.params.id}/success`);
          }
        } else {
          const responseData = await response.json();
          if (responseData.errorMessage) {
            setOpenSnackbar(true);
            setSnackbarMessage(responseData.errorMessage);
            setSubmitting(false);
          }
        }
      }).catch(() => {
        setOpenSnackbar(true);
        setSnackbarMessage('An error has occured. Failed to submit form.');
        setSubmitting(false);
      });
    };
    if (!isDisabled) {
      submit();
    }
  };

  const handleValidation = (values) => {
    const errors = {};
    let newErrorMessage = '';
    if (fields && fields.length > 0) {
      fields.forEach((field) => {
        if (field.required && (!values[field.name] || (formatFieldType(field, values) !== 'number' && isEmpty(values[field.name])))) {
          errors[field.name] = `${field.label} is required`;
        }
        if (values[field.name]) {
          let isValid = true;
          let format = '';
          if (['mobile', 'emergency_contact_mobile'].includes(field.name)) {
            const reg = /^[0-9]*$/;
            if (!reg.test(values[field.name])) {
              isValid = false;
              newErrorMessage = 'Mobile should only contain numbers without space';
            } else if (!(values[field.name].length >= 7 && values[field.name].length <= 15)) {
              isValid = false;
              newErrorMessage = 'Mobile should be within 7 to 15 digits';
            }
          } else if (['nric', 'emergency_contact_nric'].includes(field.name)) {
            isValid = myNRICValidation(values[field.name]);
            format = !isValid ? 'MyKad (NRIC) format' : '';
          } else {
            switch (formatFieldType(field, values)) {
              case 'time':
                isValid = moment(values[field.name], 'HH:mm', true).isValid();
                format = !isValid ? 'time format' : '';
                break;
              case 'date':
                isValid = moment(values[field.name]).isValid();
                format = !isValid ? 'date format' : '';
                break;
              case 'email':
                isValid = emailValidation(values[field.name]);
                format = !isValid ? 'email address' : '';
                break;
              default:
                break;
            }
          }
          if (!isValid) {
            errors[field.name] = newErrorMessage || `This is not a valid ${format}`;
          }
        }
      });
    }
    return errors;
  };

  const handleAgreeTerms = () => {
    if (!isPreview()) {
      setAgreeTerms(prev => !prev);
    }
  };

  const renderTerms = (formik) => {
    if (!data.terms_and_conditions) return null;
    return (
      <Grid container alignItems="flex-start" wrap="nowrap">
        <Checkbox
          color="primary"
          name="terms_and_conditions"
          checked={agreeTerms}
          disabled={formik.isSubmitting}
          onChange={handleAgreeTerms}
        />
        <div className={classes.terms}>
          { renderParsedHTML(data.terms_and_conditions) }
        </div>
      </Grid>
    );
  };

  const renderSubmissionClosed = () => (
    <div className={classes.container}>
      <Typography>
        Submission is closed.
      </Typography>
    </div>
  );

  const renderForm = () => (
    <div className={`${classes.container} white`}>
      <Typography variant="h1">
        { data.header }
      </Typography>
      <div className={classes.description}>
        { renderParsedHTML(data.description) }
      </div>

      {
        fields && fields.length > 0 && (
          <Formik
            initialValues={getInitialData()}
            validate={handleValidation}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {formik => (
              <Form noValidate className={classes.form}>
                {
                  React.Children.toArray(
                    fields.map(field => renderFieldType(formik, field)),
                  )
                }
                { renderTerms(formik) }
                <Grid container justify="center">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      isPreview()
                      || formik.isSubmitting
                      || !!(data.terms_and_conditions && !agreeTerms)
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        )
      }
    </div>
  );

  return (
    <>
      {
        data.accept_sub
          ? renderForm()
          : renderSubmissionClosed()
      }
      <Snackbar
        open={openSnackbar}
        message={snackbarMessage}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

SubmissionForm.propTypes = {
  data: PropTypes.instanceOf(Object),
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
    path: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

SubmissionForm.defaultProps = {
  data: {},
};

export default withRouter(SubmissionForm);
