import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'flag-icon-css/css/flag-icon.min.css';

import { FormFooter } from '../../components';

import Routes from '../../Routes';

import theme from './theme/theme';

import './styles.css';

class LocalizedUtils extends MomentUtils {
  getWeekdays() {
    return [0, 1, 2, 3, 4, 5, 6].map(dayOfWeek => this.moment().weekday(dayOfWeek).format('dd')[0]);
  }
}

const App = () => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={LocalizedUtils}>
      <Router>
        <div className="AppContainer">
          <Routes />
          <FormFooter />
        </div>
      </Router>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export default App;
