import oddOrEven from './number';

// Only supports MY NRIC until 99 years old
export default function getInfoFromIC(ic) {
  let gender = '';
  let dateOfBirth = '';
  let birthYear = '';
  let birthMonth = '';
  let birthDay = '';
  if (ic) {
    const myICRegex = new RegExp(/^([0-9][0-9])((0[1-9])|(1[0-2]))((0[1-9]|[1-2][0-9]|3[0-1]))([0-9][0-9])([0-9][0-9][0-9][0-9])$/, 'i');
    // Remove hyphen and spaces
    const formattedIC = ic.replace(/-|\s/g, '');
    // Check if ic format is valid
    if (myICRegex.test(formattedIC)) {
      // ------ Get date of birth ------
      const year = formattedIC.substring(0, 2);
      const month = formattedIC.substring(2, 4);
      const day = formattedIC.substring(4, 6);
      const currentYear = (new Date()).getFullYear();
      const currentYearFirstDigit = parseInt(currentYear.toString().substring(0, 1), 10);
      const currentCentury = currentYearFirstDigit * 1000;
      const cutoff = currentYear - currentCentury;
      const currentCenturyICDigits = currentYearFirstDigit * 10;
      const centuryBeforeCurrentICDigits = currentCenturyICDigits - 1;
      const fullBirthYear = `${year > cutoff ? centuryBeforeCurrentICDigits.toString() : currentCenturyICDigits.toString()}${year}`;

      dateOfBirth = `${fullBirthYear}-${month}-${day}`;
      birthYear = fullBirthYear;
      birthMonth = month;
      birthDay = day;

      // ------- Get gender -------
      const genderDigits = formattedIC.substring(formattedIC.length - 4);
      switch (oddOrEven(genderDigits)) {
        case 'odd':
          gender = 'male';
          break;
        case 'even':
          gender = 'female';
          break;
        default:
          break;
      }
    }
  }

  return {
    year: birthYear,
    month: birthMonth,
    day: birthDay,
    dateOfBirth,
    gender,
  };
}
